<template>
  <div class="page-container">
    
    <div class="section-container">
      <div class="box-header">
        <form v-on:submit.prevent="on_submit"> 
          <h3>{{ item.name }}</h3>
          <p>{{ item.descr }}</p>

          <img v-if="item.img_url" :src="item.img_url" />

          <!-- Conditional rendering for CUSTOM_DONATION -->
          <div class="input-container">
            <memlist-text-input
              id="amount"
              name="amount"
              layout="vertical"
              :title="'Donationssumma SEK'"
              variation="enhanced"
              validation_type="TEXT"
              v-model="amount"
              :placeholder="$t('PUBLIC.FORM.CUSTOM_DONATION_AMOUNT')"
              :required="false"
              @validated="validated"
              @update:value="update_amount"
            ></memlist-text-input>
          </div>
        
        <p style="color: red;" v-if="error">{{ error }}</p>

        <button type="submit" class="extra-item-button">{{ $t('COMMON.CONTINUE') }}</button>
    
      </form>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  data() {
    return {
      error: null,
      item: {
        shop_item_id: 11,
        type: 'SHIPPING_ITEM',
        selected: false,
        name: 'Extra Item 1',
        amount: '5',
        currency: 'SEK',
        description: 'Description for extra item 1',
        img_url: 'http://localhost:3020/dlfile/7TZe1m6WW6kthf397sUy5OFPSKUvKGH6Cwa6hXW4QrbdNSbkL0pqo9EwFyLIr0qZ',
      },
      cart_items: [],
    };
  },

  async mounted() {
    const shop_item = await this.fetch_shop_item(this.$route.params.shop_item_id);

    this.item = shop_item;
  },
  methods: {
    on_submit() {
      const amount = parseInt(this.amount+'');

      if (amount <= 0 || isNaN(amount)) {
        this.error = this.$t('SHOP_ITEM.MUST_BE_ABOVE_0');
        return;
      }
    },
    update_amount(field, value) {},
    validated() {},
    validate() {},

    async fetch_shop_item(id) {
      const res = await axios.get(`/shop_item/${id}`);

      if (res.status === 200) {
        return res.data;
      }

      return null;
    },
  },
};
</script>

<style scoped>
/* Container for the entire page */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
}

/* Header styling */
.header {
  text-align: center;
  margin-bottom: 20px;
}

/* Section container */
.section-container {
  width: 100%;
  max-width: 600px; /* Constrain the width on larger screens */
  margin: 20px 0;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Input container to give padding and spacing */
.input-container {
  margin-bottom: 20px;
}

/* Description styling */
.description {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

/* Button styling */
.extra-item-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #2d9bf0;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}

.extra-item-button:hover {
  background-color: #2380c1;
}

/* Continue button styling */
.continue-button {
  margin-top: 20px;
  max-width: 700px;
  width: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* On mobile, the container will take the full width */
  .section-container {
    width: 100%;
    padding: 10px;
    box-shadow: none; /* Remove shadow on mobile for a flatter design */
    border-radius: 0;
  }

  .extra-item-button,
  .continue-button {
    width: 100%;
  }
}
</style>
